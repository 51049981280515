<template>
    <div class="gantt-wrapper">
      <div class="ganttTop">
        <div class="topLeft">
            <div @click="leave(1)">主页</div><span>/</span>
            <div> 智能甘特</div><span>/</span>
            <div> 装配计划</div>
        </div>
        <div class="topRight">
          <el-tabs v-model="activeName"  :before-leave='tabClick'>
            <el-tab-pane label="人员/设备使用甘特图" name="assembly"></el-tab-pane>
            <el-tab-pane v-premiSub="'设备甘特图_工艺甘特图'" label="工艺甘特图" name="craftGantt"></el-tab-pane>
            <el-tab-pane v-premiSub="'日历甘特图'" label="日历甘特图" name="calendarGantt"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="goback" @click="fullscreen"><img src="@/assets/images/gantt/fullscreen.png">全屏</div>
      </div>
  
      <div class="ganttContent">
        <component ref='cmp' :is="currentComponent"></component>
      </div>
      <tip-dialog :tipDialog.sync='tipDialog' :tipText="tipText" @confirm='confirm'/>
      
    </div>
  </template>
  
  <script>
  import {mapMutations} from 'vuex'
  import assembly from './components/AssemblyGantt'
  import craftGantt from './components/AssemblyCraftGantt'
  import calendarGantt from './components/AssemblyCalendarGantt'
  import TipDialog from './components/TipDialog.vue'
  import {cancelSave} from '@/api/assemblyGantt.js'
  export default {
    name: "gantt",
    components:{assembly,craftGantt,calendarGantt,TipDialog},
    data() {
      return {
        activeName:"assembly",
        prevActiveName:'assembly',
        currentComponent:"assembly",
        componentNames:['assembly','craftGantt','calendarGantt'],
        tipDialog:false,
        tipText:'',
        curTip:'',
      };
    },
    created(){
      
      this.$nextTick(()=>{
        document.getElementsByClassName('el-aside')[0].style.setProperty("width", '0px');
      })
    },
    destroyed(){
      document.getElementsByClassName('el-aside')[0].style.setProperty("width", '240px');
    },
    
    methods:{
      ...mapMutations(["stateValueHandler"]),
      tabClick(activeName){
        // if(activeName=='calendarGantt'){return false}
        if(this.$refs.cmp.curStep!=1&&this.$refs.cmp.isShowGantt || 
          this.$refs.cmp.curStep==1&&this.$refs.cmp.scheduledInfo.scheduledStatus==0 &&this.$refs.cmp.isShowGantt){
          this.$message.warning({
                    showClose: true,
                    message:'请先保存或清空数据',
                    duration:0
                });
          return false
        }
        this.currentComponent=activeName;
      },
      leave (type){
        if(this.$refs.cmp.curStep!=1&&this.$refs.cmp.isShowGantt|| 
          this.$refs.cmp.curStep==1&&this.$refs.cmp.scheduledInfo.scheduledStatus==0&&this.$refs.cmp.isShowGantt){
          this.tipText='当前有更新未保存，确认离开吗？'
          this.tipDialog=true;
          this.curTip=type
          return;
        }
        cancelSave();
        if(type==1){
          this.$router.push('/')
        }else{
          this.$router.back()
        }
      },
      confirm(){
        cancelSave();
        if(this.curTip==1){
          this.$router.push('/')
        }else{
          this.$router.back()
        }
        
      },
  
      fullscreen(){  
        if(!document.fullscreenElement){
          var docElm = document.documentElement;  
          //W3C   
          if (docElm.requestFullscreen) {  
              docElm.requestFullscreen();  
          }  
              //FireFox   
          else if (docElm.mozRequestFullScreen) {  
              docElm.mozRequestFullScreen();  
          }  
              //Chrome等
          else if (docElm.webkitRequestFullScreen) {  
              docElm.webkitRequestFullScreen();  
          }  
            //IE11   
          else if (docElm.msRequestFullscreen) {  
              docElm.msRequestFullscreen();  
          }  
          // this.isFull=true;
        }else{
          if (document.exitFullscreen) {  
              document.exitFullscreen();  
          }  
          else if (document.mozCancelFullScreen) {  
              document.mozCancelFullScreen();  
          }  
          else if (document.webkitCancelFullScreen) {  
              document.webkitCancelFullScreen();  
          }  
          else if (document.msExitFullscreen) {  
              document.msExitFullscreen();  
          }
          // this.isFull=false
        }
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import "./styles/index.scss";
  </style>
  