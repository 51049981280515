<template>
    <div class="devicegntt-wrap" @click="isShowCorp=false">
      <operation
        ref="operation"
        assembly
        :curStep.sync="curStep"
        :stepLength="stepLength"
        :scheduledInfo="scheduledInfo"
        :historySerialNumbers="historySerialNumbers"
        @handleSearch="handleSearch"
        @stepChange="stepChange"
        @btnGantt="btnGantt"
        @renewGantt="renewGantt"
        @exportGantt="exportGantt"
        @schedulingSubmit="schedulingSubmit"
        @mesSync="mesSync"
        @importSync="importSync"
        @nowDate="nowDate"
      />
  
      <gantt
        ref="gantt"
        v-show="isShowGantt"
        :ganttData="ganttData"
        :updateData="updateData"
        :taskColor="corpColor"
        :ganttColumns="ganttColumns"
        :dragDrop="dragDrop"
        :scheduledInfo="scheduledInfo"
        :columnTexts="columnTexts"
        :curStep.sync="curStep"
        :stepLength.sync="stepLength"
        :tempSerialNum.sync="tempSerialNum"
        :tooltipFun="tooltipFun"
        @selectTaskDetail="selectTaskDetail"
        @selectProcedure="selectProcedure"
        @handleDrop="handleDrop"
      />
  
      <div class="dvganttFoot footDiv">
        <div class="footLeft">
          <!-- <div>
            准时率：
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="scheduledInfo.punctuality"
              define-back-color="#444444"
              text-color="#fff"
              status="success"
            ></el-progress>
          </div>
          <div>设定时间：{{ scheduledInfo.setTime }}</div> -->
        </div>
        <div class="colorInfo">
          <div class="colorItem"><span></span>调机时间</div>
          <div class="corpBtn" v-premiSub="'设备甘特图_查看客户'" @click.stop="isShowCorp=!isShowCorp">查看客户</div>
          <transition name="fade" >
          <div class="corpDiv" v-if="isShowCorp" @click.stop="">
            <div class="colorItem" v-for="(item, index) in corpColor" :key="index">
              <span :style="{ background: item }"></span>{{ index }}
            </div>
          </div>
          </transition>
        </div>
      </div>
      <!-- 详情弹框 -->
      <detail-dialog
        type="assembly"
        :taskDialog.sync="taskDialog"
        :taskForm="taskForm"
        @taskSubmit="taskSubmit"
      />
      <!-- 二次确认弹框 -->
      <tip-dialog
        :tipDialog.sync="tipDialog"
        :tipText="tipText"
        @confirm="confirm"
      />
  
      <!-- 同步/排程结果弹框 -->
      <res-dialog 
        :resVisible.sync="resVisible"
        :resResult="resResult"
        :resText="resText"
        :btnText="btnText"
        :iconType="iconType"
        @btnClick="resBtnClick"
      />
  
      <!-- 导入在制品弹框 -->
      <import-dialog 
        v-if="importVisible" 
        :visible.sync="importVisible"
        templateName="在制品导入模板"
        templateParam="workReporting"
        :apis="apis"
        @success="handleGanttdata('update')"
      />
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  import operation from "./Operation.vue";
  import gantt from "./Gantt.vue";
  import dayjs from "dayjs";
  import TipDialog from "../components/TipDialog.vue";
  import DetailDialog from "../components/DetailDialog.vue";
  import ResDialog from './ResDialog.vue';
  import {
    getAssemblyGantt,
    getMesSync,
    saveGantt,
    findBySchedulingNo,
    getProcedure,
    updateProcedure,
    deviceGanttSchedule,
    dragProcedure,
    cancelSave,
    importSync,
    exportScheduledLog,
    exportAssemblyPlan
  } from "@/api/assemblyGantt.js";
  import ImportDialog from '@/components/dialog/ImportDialog.vue';
  
  export default {
    components: { operation, gantt, TipDialog, DetailDialog, ResDialog, ImportDialog },
    data() {
      return {
        corpColor: {},
        ganttData: [],
        ganttColumns: [
          {
            title: { text: "人员/设备", style: { fontSize: "18px" }, y: -12 },
            categories: [],
          },
        ],
        dragDrop: {
          // draggableX: true, // 横向拖拽
          // draggableY: true, // 纵向拖拽
          // draggableEnd: false,
          // draggableStart: false,
          // dragMinY: 0, // 纵向拖拽下限
          // dragMaxY: 30, // 纵向拖拽上限
          // dragPrecisionX: 3600000, // 横向拖拽精度，单位毫秒
        },
  
        scheduledInfo: { punctuality: 100 },
        isShowGantt: true,
        searchValue: "",
        tempSerialNum: "", //待保存的流水号
        isShowCorp:false,
  
        taskDialog: false,
        tipDialog: false,
        tipText: "二次确认操作提示文字",
        curTip: 0,
  
        resVisible:false,
        resResult:true,
        resText:'',
        btnText:'',
        iconType:'',
  
        importVisible:false,
        apis:{
          import:importSync,//导入请求的接口
          // importTemplate:excelTemplate,//下载模板接口
        },
  
        taskForm: {},
        isLock: false, //是否锁定任务
        splitArr: [], //拆分任务数据数组
  
        historySerialNumbers:[],//排程使用的参考流水号
        curStep: 1,
        undoOrNext:'',
        stepLength: 1,
        columnTexts: [],
        updateData: [],
  
      };
    },
    created() {
      if (this.$route.query.serialNumber) {
        this.stateValueHandler({
          name: "curSerialNum",
          value: this.$route.query.serialNumber,
        });
        console.log('123',this.curSerialNum)
      }else{
        this.stateValueHandler({name: 'curSerialNum', value:""});
      }
      this.tempSerialNum = this.curSerialNum;
      this.handleGanttdata();
    },
    mounted() {},
    computed: {
      ...mapState(["curSerialNum"]),
    },
    methods: {
      ...mapMutations(["stateValueHandler"]),
      
      //获取装配计划甘特图数据
      handleGanttdata(update) {
        getAssemblyGantt({
          serialNumber: this.tempSerialNum,
        //   serialNumber: 'a24062801',
          searchValue: this.searchValue,
          snapshotNumber: this.curStep,
          undoOrNext: this.undoOrNext,
        }).then((res) => {
          this.undoOrNext="";
          if (res.code == 0) {
            if (update == undefined) {
              this.ganttData = this.handleResGantt(res.data);
            } else {
              this.updateData = this.handleResGantt(res.data, "update");
              // this.$message.success(`共查询 ${this.updateData.length-1} 条任务`)
            }

            if(res.msg){
              this.$message.success({showClose: true,message:res.msg,type:'success',duration:0});
            }
            this.historySerialNumbers=res.data.historySerialNumbers ?? [''];
          } else {
            this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
          }
        });
      },
      //处理返回甘特数据(默认触发甘特图初始化，传update触发甘特图更新)
      handleResGantt(resData, update) {
        console.log(update == undefined ? "init-" : "update-");
        let dataMap = resData.scheduledProcedureMap;
        let index = 0;
        let ganttArr = [];
        let colArr = [];
        let colTexts = [];
        for (let key in dataMap) {
          let arr = dataMap[key];
          arr.map((item) => {
            item.y = index;
            item.id = item.schedulingNo;
            item.name = item.partCode;
            item.completed = {};
            item.completed.amount =
              item.productionProgressPercent == 0
                ? item.shuntingTimePercent
                : item.productionProgressPercent;
            item.completed.fill =
              item.productionProgressPercent == 0
                ? "#232323"
                : item.customerColor;
            item.start = dayjs(item.procedureStartTime).valueOf();
            item.end = dayjs(item.procedureEndTime).valueOf();
            item.className = item.customerName;
            ganttArr.push(item);
          });
          index++;
          if (update == undefined) {
            colArr.push(`<div class='dvOrdinate'>${key}</div>`);
            colTexts.push(key);
          }
        }
        if (colTexts.length != 0) {
          ganttArr.push({ y: colTexts.length });
        }else if(this.columnTexts!=0){
          ganttArr.push({ y: this.columnTexts.length });
        }
        if (update == undefined) {
          colArr.push(`<div class='dvOrdinate'></div>`);
        }
        
  
        if (resData.scheduledInfo != null) {
          this.scheduledInfo = resData.scheduledInfo;
          this.tempSerialNum = resData.scheduledInfo.serialNumber;
          this.stepLength = resData.scheduledInfo.totalSnapshotNum;
          this.curStep = resData.scheduledInfo.curSnapshotNum;
        }
  
        if (update == undefined) {
          if (resData.customerColorMap != null) {
            this.corpColor = resData.customerColorMap;
          }
          this.ganttColumns[0].categories = colArr;
          this.columnTexts = colTexts;
        }
        return ganttArr;
      },
  
      //条件搜索
      handleSearch(value) { 
        if(!value){
          this.$message.warning({
                    showClose: true,
                    message:"请输入投产号、订单号或图号进行搜索",
                    duration:0
                });
          return;
        }
        if (!this.isShowGantt) {
          this.$message.warning({
                    showClose: true,
                    message:"当前暂无排程信息！",
                    duration:0
                });
          return;
        }
        this.searchValue = value;
        this.handleGanttdata();
      },
      //步骤改变
      stepChange({step,undoOrNext}) {
        // console.log('objzzzzzz',step,undoOrNext)
        if (step < 1 || step > this.stepLength) return;
        this.curStep = step;
        this.undoOrNext=undoOrNext;
        this.searchValue = "";
        this.$refs.operation.searchValue = "";
        this.handleGanttdata("update");
      },
      //保存/清空
      btnGantt(type) {
        if (!this.isShowGantt) {
          this.$message.warning({
                    showClose: true,
                    message:"当前暂无排程信息！",
                    duration:0
                });
          return;
        }
        if (
          type == 1 &&
          this.scheduledInfo.scheduledStatus != 0 &&
          this.curStep == 1
        ) {
          this.$message.warning({
                    showClose: true,
                    message:"当前暂无更新排程信息！",
                    duration:0
                });
          return;
        }
        this.curTip = type;
        this.tipText = type == 1 ? "确认保存本次更新吗？" : "确认清空当前数据吗？";
        this.tipDialog = true;
      },
      confirm() {
        if (this.curTip == 1) {
          //保存
          saveGantt({
            serialNumber: this.tempSerialNum,
            snapshotNumber: this.curStep,
          }).then((res) => {
            if (res.code == 0) {
              this.tipDialog = false;
              this.$message.success("保存成功");
              this.stateValueHandler({
                name: "curSerialNum",
                value: this.tempSerialNum,
              });
              this.curStep = 1;
              this.handleGanttdata();
            } else {
              this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
            }
          });
        } else if (this.curTip == 2) {
          //清空
          cancelSave();
          this.tempSerialNum = "";
          this.tipDialog = false;
          this.isShowGantt = false;
          this.scheduledInfo = { punctuality: 100 };
          this.corpColor = {};
          this.curStep = 1;
          this.stepLength = 1;
          this.$message.success("清空成功");
        }
      },
      //刷新
      renewGantt() {
        if (!this.isShowGantt) {
          this.$message.warning({
                    showClose: true,
                    message:"当前暂无排程信息！",
                    duration:0
                });
          return;
        }
        this.$refs.operation.searchValue = "";
        this.searchValue = "";
        this.handleGanttdata();
      },
      //导出甘特计划
      exportGantt(){
        console.log('导出装配计划')
        exportAssemblyPlan(this.scheduledInfo.serialNumber).then(res => {
          // console.log('装配甘特导出返回：',res)
          let blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'});
          let a = document.createElement('a')
          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = `装配计划${this.scheduledInfo.serialNumber}-${dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}`
          document.body.appendChild(a)
          a.style.display = 'none'
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
          this.$message.success('导出成功！')
        })
      },
      //回到当日
      nowDate() {
        if (!this.isShowGantt) {
          this.$message.warning({
                    showClose: true,
                    message:"当前暂无排程信息！",
                    duration:0
                });
          return;
        }
        this.$refs.gantt.nowDate();
      },
  
      //导入在制品同步
      importSync(){
        if (!this.isShowGantt) {
          this.$message.warning({
                    showClose: true,
                    message:"当前暂无排程信息！",
                    duration:0
                });
          return;
        }
        if (this.scheduledInfo.scheduledStatus == 0 || this.curStep > 1) {
          this.$message.warning({
                    showClose: true,
                    message:"当前有更新待保存！",
                    duration:0
                });
          return;
        }
        this.importVisible=true;
      },
      
  
      //MES同步
      mesSync() {
        if (!this.isShowGantt) {
          this.$message.warning({
                    showClose: true,
                    message:"当前暂无排程信息！",
                    duration:0
                });
          return;
        }
        if (this.scheduledInfo.scheduledStatus == 0 || this.curStep > 1) {
          this.$message.warning({
                    showClose: true,
                    message:"当前有更新待保存！",
                    duration:0
                });
          return;
        }
        this.$refs.operation.searchValue = "";
        this.searchValue = "";
        //同步接口
        getMesSync().then((res) => {
          if (res.code == 0) {
            this.resResult=true;
            this.handleGanttdata("update");
            this.resText="MES 同步成功";
            this.iconType="success";
          } else {
            this.resResult=false;
            this.resText="MES 同步失败";
            this.btnText="重新同步";
            this.iconType="error";
          }
          this.resVisible=true;
        });
      },
  
      //排程
      schedulingSubmit(params) {
        console.log("schedulingSubmit", params);
        deviceGanttSchedule(params).then((res) => {
          if (res.code == 0) {
            this.isShowGantt = true;
            this.tempSerialNum = res.data.scheduledInfo.serialNumber;
            this.$refs.operation.searchValue = "";
            this.searchValue = "";
            this.handleGanttdata();
            if(res.msg=='排程成功'){
              this.$message.success(res.msg);
            }else{
              this.resResult=false;
              this.resText=res.msg;
              this.btnText='查看原因';
              this.iconType="wraning";
              this.resVisible=true;
            }
          } else {
            this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
          }
        });
      },
  
      //同步/排程失败弹框按钮点击处理
      resBtnClick(){
        if(this.btnText=="重新同步"){
          //重新同步
          this.mesSync();
        }else{
          //排产打印日志
          exportScheduledLog(this.tempSerialNum).then(res=>{
            let blob = new Blob([res], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'
            });
            let a = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = '排程日志'+dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
            // 将a标签添加到body中是为了更好的兼容性，谷歌浏览器可以不用添加
            document.body.appendChild(a);
            a.style.display = 'none';
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
        }
      },
  
      //双击根据排产号查任务详情
      selectTaskDetail(schedulingNo,selectedDevices) {
        findBySchedulingNo({
          serialNumber: this.tempSerialNum,
          schedulingNo: schedulingNo,
          snapshotNumber: this.curStep,
          curSelectedStaff: selectedDevices,
        }).then((res) => {
          if (res.code == 0) {
            this.taskForm = res.data;
            this.taskForm.scheduledStatus=this.scheduledInfo.scheduledStatus;
            //当前人员
            this.taskForm.selectedDevices=this.taskForm.selectedStaffs[0].staffName;
            //协作人员
            let staffsStr=''
            this.taskForm.selectedStaffs.map((i)=>staffsStr+=i.staffName+'，');
            this.taskForm.collaborators=staffsStr.substring(0,staffsStr.length-1);
            this.taskDialog = true;
            // console.log(11111,this.taskForm)
          } else {
            this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
          }
        });
      },
      //编辑任务详情（修改工序）
      taskSubmit(params) {
        params.snapshotNumber = this.curStep;
        // console.log('taskForm',params)
        //提交编辑数据接口
        updateProcedure(params).then((res) => {
          if (res.code == 0) {
            // this.handleResGantt(res.data,'update');
            this.$message.success('修改成功！')
            // this.curStep += 1;
            this.curStep = res.data.scheduledInfo.totalSnapshotNum;
            this.tempSerialNum = res.data.scheduledInfo.serialNumber;
            this.$refs.operation.searchValue = "";
            this.searchValue = "";
            this.handleGanttdata("update");
            this.taskDialog = false;
            
          } else {
            this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
          }
        });
      },
      //单机根据投产号查所有工序连线
      selectProcedure(productionNo) {
        getProcedure({
          serialNumber: this.tempSerialNum,
          productionNo: productionNo,
          snapshotNumber: this.curStep,
        }).then((res) => {
          if (res.code == 0) {
            // console.log('所有工序信息',res.data);
            // let resData = this.handleResGantt(res.data, "update");
            let resData = this.handleResGantt(res.data);
            let sortArr = res.data.sortedProcedures.map((item) => {
              return item.schedulingNo;
            });
            resData.map((item) => {
              if (item.selectedDevices != undefined) {
                item.y = this.columnTexts.indexOf(item.selectedDevices);
              }
  
              let num = sortArr.indexOf(item.id);
              console.log('连线排序',num)
              if (num > 0) {
                let sno = sortArr[num - 1];
                item.dependency = {
                  to: sno,
                  lineColor: item.customerColor,
                  lineWidth: 2,
                  marker: { color: item.customerColor },
                };
              }
            });
            // this.updateData = resData;
            this.ganttData = resData;
            this.$message.success(`共查询 ${this.ganttData.length-1} 条工序`)
          } else {
            this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
          }
        });
      },
      //甘特图拖拽验证
      handleDrop(dropData) {
        // console.log(this.scheduledInfo.scheduledStatus);
        // if(this.scheduledInfo.scheduledStatus===2){
        //   this.$message.warning("该排程使用中无法更新！");
        //   this.handleGanttdata("update");
        //   return;
        // }
        // if(this.scheduledInfo.scheduledStatus===3){
        //   this.$message.warning("该排程已发布无法更新！");
        //   this.handleGanttdata("update");
        //   return;
        // }
        dragProcedure(dropData).then((res) => {
          if (res.code == 0) {
            // console.log('拖拽验证成功',res.data);
            this.tempSerialNum = res.data.scheduledInfo.serialNumber;
            this.curStep += 1;
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
              duration: 8000,
              offset: 50,
              customClass: "msgClass",
            });
          }
          this.handleGanttdata("update");
        });
      },
  
      
      //任务提示框详情
      tooltipFun(point){
        return `<div style='height:410px;line-height:34px;padding:0px 10px;'>
                  <div style='font-size: 24px;font-weight: bold;margin:4px 0px';>
                    ${point.partCode}
                  </div>
                  人员/设备： ${point.selectedStaffName}<br/>
                  工步名称： ${point.procedureName}<br/>
                  工单号： ${point.orderNo}<br/>
                  排产号： ${point.schedulingNo}<br/>
                  计划生产数量： ${point.plannedQuantity}<br/>
                  已完成数量： ${point.completedQuantity == null? "0": point.completedQuantity}<br/>
                  计划开始时间：${dayjs(point.start).format("YYYY-MM-DD HH:mm:ss")}<br/>
                  计划完成时间：${dayjs(point.end).format("YYYY-MM-DD HH:mm:ss")}<br/>
                  完成周期：${point.totalTimeStr}<br/>
                  工单需求日期：${point.orderCompletionDate}<br/>
                  交付情况：<span class="${point.overdueStatus==0?'deliverGreen':'deliverRed'}">${point.varianceDay}</span>
                </div>`;
      },
      
    },
  };
  </script>
  <style lang="scss">
  
  .msgClass {
    .el-message__content {
      font-size: 20px;
    }
  }
  .dvOrdinate {
    width: 13vw;
    text-align: center;
    font-size: 20px;
  }
  </style>
  
  <style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: all 0.5s ease-in-out;
  }
  .fade-enter, .fade-leave-to {
    transform:translateX(100%);
  }
  /* 隐藏滚动条 */
  ::-webkit-scrollbar {
    display: none;
  }
  // .devicegntt-wrap{
  //   height: 100%;
  //   overflow: hidden;
  // }
  .dvganttFoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    width: 100%;
    background: #232323;
    position: fixed;
    bottom: 0px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #b9b9b9;
    box-shadow: 0px 10px 30px rgb(0, 0, 0);
    .footLeft {
      width: 480px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      > div {
        display: flex;
        align-items: center;
      }
    }
    .colorInfo {
      display: flex;
      // position: relative;
      .colorItem {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          margin-right: 8px;
        }
        // margin-right: 30px;
      }
      > div:first-child {
        span {
          background: #ffffff;
        }
        margin-right: 30px;
      }
      .corpBtn{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100px;
        height: 40px;
        background: #232323;
        border: 1px solid #4F4F4F;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 400;
        color: #E8E8E8;
        margin-right: 40px;
        user-select: none;
      }
      .corpDiv{
        position:fixed;
        bottom: 60px;
        right: -20px;
        background: #232323;
        padding:10px 30px 10px 20px;
        box-shadow: 0px 0.52083vw 1.5625vw rgba(0, 0, 0, 0.5);
        border-radius: 6px;
        white-space: nowrap;
        max-height: 800px;
        overflow-y: scroll;
        // 滚动条
        ::-webkit-scrollbar {
            width: 7px;
        }
        ::-webkit-scrollbar-thumb {
            background: #4B4B4B;
            border-radius: 4px;
        }
      }
    }
    ::v-deep .el-progress-bar__outer {
      width: 165px;
      border-radius: 4px;
    }
    ::v-deep .el-progress-bar__inner {
      border-radius: 4px;
    }
  }
  </style>