<template>
  <div>
    <operation  ref="operation"
      craft
      :curStep.sync='curStep' 
      :stepLength='stepLength' 
      :scheduledInfo='scheduledInfo'
      @handleSearch='handleSearch'
      @stepChange='stepChange'
      @btnGantt='btnGantt'
      @renewGantt='renewGantt'
      @exportGantt='exportGantt'
      @schedulingSubmit='schedulingSubmit'
      @mesSync='mesSync'
      @importSync="importSync"
      @nowDate='nowDate'/>

    <gantt ref="gantt"  v-show="isShowGantt"
      :ganttData="ganttData" 
      :updateData="updateData" 
      :taskColor="colors" 
      :ganttColumns='ganttColumns' 
      :dragDrop='dragDrop' 
      :scheduledInfo='scheduledInfo'
      :columnTexts='columnTexts'
      :curStep.sync='curStep'  
      :stepLength.sync='stepLength'
      :tempSerialNum.sync='tempSerialNum'
      :tooltipFun="tooltipFun"
      @selectTaskDetail='selectTaskDetail'
      @handleDrop='handleDrop'/>
    <div class="cfganttFoot footDiv">
        <div><span></span> 正常</div>
        <div><span></span> 逾期</div>
    </div>
    <!-- 任务详情弹框 -->
    <detail-dialog  
      type="assembly"
      :taskDialog.sync='taskDialog' 
      :taskForm='taskForm' 
      @taskSubmit='taskSubmit'/>
    <!-- 二次确认弹框 -->
    <tip-dialog :tipDialog.sync='tipDialog' :tipText="tipText" @confirm='confirm'/>
    <!-- 同步/排产结果弹框 -->
    <res-dialog 
      :resVisible.sync="resVisible"
      :resResult="resResult"
      :resText="resText"
      :btnText="btnText"
      @btnClick="resBtnClick"
    />
    <!-- 导入在制品弹框 -->
    <import-dialog 
      v-if="importVisible" 
      :visible.sync="importVisible"
      templateName="在制品导入模板"
      templateParam="workReporting"
      :apis="apis"
      @success="handleGanttdata('update')"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import operation from './Operation.vue'
import gantt from './Gantt.vue'
import dayjs from "dayjs";
import TipDialog from '../components/TipDialog.vue'
import DetailDialog from '../components/DetailDialog.vue'
import ResDialog from './ResDialog.vue';
import ImportDialog from '@/components/dialog/ImportDialog.vue';
import {getProcedureGantt,getMesSync,saveGantt,findBySchedulingNo,updateProcedure,deviceGanttSchedule,dragProcedure,cancelSave,importSync,exportScheduledLog} from '@/api/assemblyGantt.js'
export default {
    components:{operation,gantt,TipDialog,DetailDialog,ResDialog,ImportDialog},
    data(){
      return{
        scheduledInfo:{},
        colors:{
            normal: "#2DEB79",
            overdue: "#FF4D4F",
            parent:"#2DEB79",
        },
        ganttData: [
          {id: "aa",name: "111111",corp: "parent",start: "2023-1-19",end:'2023-2-19',pch: "排产号",gx:'工序',y:0 },
        ],
        ganttColumns:[
          {
            title: { text: "工艺工序",style:{ fontSize:'18px'},align:'low',x:10,y:-12 },
            categories:[]
          },
          {
            categories:[],
          },
        ],
        dragDrop:{
          // draggableX: true, // 横向拖拽
          // draggableEnd:false,
          // draggableStart:false
          // dragPrecisionX: 3600000, // 横向拖拽精度，单位毫秒
        },

        isShowGantt:true,
        searchValue:'',
        tempSerialNum:'',//待保存的流水号

        taskDialog:false,
        tipDialog:false,
        tipText:'二次确认操作提示文字',
        curTip:0,
        resVisible:false,
        resResult:true,
        resText:'',
        btnText:'',
        importVisible:false,
        apis:{
          import:importSync,//导入请求的接口
          // importTemplate:excelTemplate,//下载模板接口
        },

        taskForm:{},

        curStep:1,
        stepLength:1,
        updateData:[],
        columnTexts:[],

        
      }
    },
    created(){
      if(this.curSerialNum!=""){
        this.tempSerialNum=this.curSerialNum;
      }
      this.handleGanttdata();
      
      
    },
    mounted(){
      // this.editData();
    },
    watch:{
      
    },
    computed:{
      ...mapState(['curSerialNum']),

    },
    methods:{
      editData() {
        this.ganttData = this.ganttData.map((item) => {
            item.start = dayjs(item.start).valueOf();
            item.end = dayjs(item.end).valueOf();
            // item.color = this.color[item.corp];
            // item.taskColor = this.taskColor[item.corp];
            item.className = item.corp;
            return item;
        });
          // this.dataList.push(this.ganttData);
      },
      //获取甘特图数据
        handleGanttdata(update) {
            getProcedureGantt({serialNumber:this.tempSerialNum,searchValue:this.searchValue,snapshotNumber:this.curStep}).then(res=>{
                if(res.code==0){
                    if(update==undefined){
                        console.log(res.data)
                        this.ganttData=this.handleResGantt(res.data);
                    }else{
                        this.updateData=this.handleResGantt(res.data,'update');
                    }
                    
                }else{
                    this.$message.warning({
                      showClose: true,
                      message:res.msg,
                      duration:0
                  });
                }
                
            })
        },
        //处理返回甘特数据(默认触发甘特图初始化，传update触发甘特图更新)
        handleResGantt(resData,update){
            console.log(update==undefined?'初始化':'更新')
            let dataMap=resData.scheduledProcedureMap;
            let index=0;
            let ganttArr=[];
            let colArr=[];
            let colArr2=[];
            let colTexts=[];
            for(let key in dataMap){
                let arr=dataMap[key];
                arr.map(item=>{
                    item.y=index;
                    item.id=item.schedulingNo
                    // item.name=item.schedulingNo==""?item.productionNo:item.schedulingNo;
                    item.name = item.partCode;
                    item.completed={};
                    item.overdueStatus=item.overdueStatus==1? "overdue":"normal";
                    if(item.schedulingNo!=""){
                      item.completed.amount=item.productionProgressPercent==0?
                        item.shuntingTimePercent:item.productionProgressPercent;
                      item.completed.fill=item.productionProgressPercent==0?
                        '#232323':this.colors[item.overdueStatus] ;
                    }
                    
                    item.start = dayjs(item.procedureStartTime).valueOf();
                    item.end = dayjs(item.procedureEndTime).valueOf();
                    item.className = item.schedulingNo==""?"parent": item.overdueStatus;
                    if(item.schedulingNo==""){
                      item.schedulingNo='排产号';
                      item.procedureName='工步名称';
                    }
                    // console.log('item',item)
                    ganttArr.push(item);
                    if(update==undefined){
                      let fontStyle=item.procedureName=='工步名称'? 'font-weight:800;color:#fff':'';
                      colArr.push(`<div class='cfOrdinate1' style='${fontStyle}'>&nbsp;&nbsp;&nbsp;&nbsp;${item.schedulingNo}</div>`);
                      colArr2.push(`<div class='cfOrdinate2' style='${fontStyle}'>${item.procedureName}</div>`);
                      colTexts.push(key);
                    }
                    index++;
                })
                
            }
            if(colTexts.length!=0){
              ganttArr.push({y:colTexts.length});
            }else if(this.columnTexts!=0){
              ganttArr.push({ y: this.columnTexts.length });
            }
            if(update==undefined){
              colArr.push(`<div ></div>`);
              colArr2.push(`<div ></div>`);
            }
            
            
            if(resData.scheduledInfo!=null){
              this.scheduledInfo=resData.scheduledInfo;
              this.tempSerialNum=resData.scheduledInfo.serialNumber;
              this.stepLength=resData.scheduledInfo.totalSnapshotNum;
              this.curStep=resData.scheduledInfo.curSnapshotNum;
            }
            
            if(update==undefined){//初始化
                this.columnTexts=colTexts;
                this.ganttData=ganttArr;
                this.ganttColumns[0].categories=colArr;
                this.ganttColumns[1].categories=colArr2;
            }
            return ganttArr;
            
        },

      //条件搜索
      handleSearch(value){
        // console.log('搜索',value)
        if(!value){
          this.$message.warning({
                    showClose: true,
                    message:"请输入投产号、订单号或图号进行搜索",
                    duration:0
                });
          return;
        }
        if(!this.isShowGantt){
            this.$message.warning({
                    showClose: true,
                    message:'当前暂无排程信息！',
                    duration:0
                });return;
        }
        this.searchValue=value;
        this.handleGanttdata();

        // getProcedureGantt({serialNumber:this.tempSerialNum,searchValue:this.searchValue,snapshotNumber:this.curStep}).then(res=>{
        //         if(res.code==0){
        //           this.ganttData=this.handleResGantt(res.data,'update');
        //         }else{
        //             this.$message.warning(res.msg);
        //         }
                
        //     })
        // this.ganttData=this.handleResGantt(res.data,'update');
        
      },
      //步骤改变
      stepChange(step){
        console.log('步骤',step)
        if(step==0||step==this.stepLength) return;
        this.curStep=step;
        this.searchValue='';
        this.$refs.operation.searchValue='';
        this.handleGanttdata();
      },
      
      

      //保存/清空
      btnGantt(type){
        if(!this.isShowGantt){
            this.$message.warning({
                    showClose: true,
                    message:'当前暂无排程信息！',
                    duration:0
                });
            return;
        }
        if(type==1 && this.scheduledInfo.scheduledStatus!=0 && this.curStep==1){
            this.$message.warning({
                    showClose: true,
                    message:'当前暂无更新排程信息！',
                    duration:0
                });
            return;
        }
        this.curTip=type,
        this.tipText=type==1? '确认保存本次更新吗？':'确认清空当前数据吗？'
        this.tipDialog=true;
      },
      confirm(){
          if(this.curTip==1){
            //保存
            saveGantt({serialNumber:this.tempSerialNum}).then(res=>{
                if(res.code==0){
                    this.tipDialog=false;
                    this.$message.success('保存成功');
                    this.stateValueHandler({name:'curSerialNum', value:this.tempSerialNum});
                    this.curStep=1;
                    this.handleGanttdata();
                }else{
                    this.$message.warning({
                      showClose: true,
                      message:res.msg,
                      duration:0
                    });
                }   
            })
          }else if(this.curTip==2){
            //清空
            cancelSave();
            this.tempSerialNum=''
            this.tipDialog=false;
            this.isShowGantt=false;
            this.scheduledInfo={punctuality:100};
            this.corpColor={};
            this.curStep=1;
            this.stepLength=1;
            this.$message.success('清空成功');
          }
      },

      //刷新
      renewGantt(){
        if(!this.isShowGantt){
            this.$message.warning({
                    showClose: true,
                    message:'当前暂无排程信息！',
                    duration:0
                });return
        }
        this.$refs.operation.searchValue='';
        this.searchValue='';
        this.handleGanttdata();
      },
      //导出甘特
      exportGantt(){

      },

      //回到当日
      nowDate(){
        if(!this.isShowGantt){
            this.$message.warning({
                    showClose: true,
                    message:'当前暂无排程信息！',
                    duration:0
                }); return;
        }
        this.$refs.gantt.nowDate()
      },
      //导入在制品同步
      importSync(){
        if (!this.isShowGantt) {
          this.$message.warning({
                    showClose: true,
                    message:"当前暂无排程信息！",
                    duration:0
                });
          return;
        }
        if (this.scheduledInfo.scheduledStatus == 0 || this.curStep > 1) {
          this.$message.warning({
                    showClose: true,
                    message:"当前有更新待保存！",
                    duration:0
                });
          return;
        }
        this.importVisible=true;
      },
      //MES同步
      mesSync(){
        if(!this.isShowGantt){
            this.$message.warning({
                    showClose: true,
                    message:'当前暂无排程信息！',
                    duration:0
                });return;
        }
        if(this.scheduledInfo.scheduledStatus==0 || this.curStep>1){
            this.$message.warning({
                    showClose: true,
                    message:'当前有更新待保存！',
                    duration:0
                });return;
        }
        //同步接口
        getMesSync().then(res=>{
          if (res.code == 0) {
            this.resResult=true;
            this.handleGanttdata("update");
            this.resText="MES 同步成功";
          } else {
            this.resResult=false;
            this.resText="MES 同步失败";
            this.btnText="重新同步";
          }
          this.resVisible=true;
        })
      },

      //排程
      schedulingSubmit(params){
        console.log('排程',params);
        deviceGanttSchedule(params).then(res=>{
          if(res.code==0){
            this.isShowGantt=true;
            this.tempSerialNum=res.data.scheduledInfo.serialNumber;
            this.handleGanttdata();
            if(res.msg=='排程成功'){
              this.$message.success(res.msg);
            }else{
              this.resResult=false;
              this.resText=res.msg;
              this.btnText='查看原因';
              this.resVisible=true;
            }
          }else{
            this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
          }
        })
          
      },

      //同步/排程失败弹框按钮点击处理
      resBtnClick(){
        if(this.btnText=="重新同步"){
          //重新同步
          this.mesSync();
        }else{
          //排产打印日志
          exportScheduledLog(this.tempSerialNum).then(res=>{
            let blob = new Blob([res], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'
            });
            let a = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            // a.download = '排程日志';
            // 将a标签添加到body中是为了更好的兼容性，谷歌浏览器可以不用添加
            document.body.appendChild(a);
            a.style.display = 'none';
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
        }
      },

      //双击根据排产号查任务详情
      selectTaskDetail(schedulingNo){
        if(schedulingNo=="排产号"){return}
        findBySchedulingNo({serialNumber:this.tempSerialNum,schedulingNo:schedulingNo,snapshotNumber:this.curStep}).then(res=>{
                if(res.code==0){
                    this.taskForm=res.data;
                    // this.taskForm.scheduledStatus=2;
                    // this.taskDialog=true
                    this.taskForm.scheduledStatus=this.scheduledInfo.scheduledStatus;
                    //当前人员
                    this.taskForm.selectedDevices=this.taskForm.selectedStaffs[0].staffName;
                    //协作人员
                    let staffsStr=''
                    this.taskForm.selectedStaffs.map((i)=>staffsStr+=i.staffName+'，');
                    this.taskForm.collaborators=staffsStr.substring(0,staffsStr.length-1);
                    this.taskDialog = true;
                }else{
                    this.$message.warning({
                      showClose: true,
                      message:res.msg,
                      duration:0
                    })
                }
            })
      },
      //编辑任务详情（修改工序）
      taskSubmit(params){
        params.snapshotNumber=this.curStep;
        //提交编辑数据接口
        updateProcedure(params).then(res=>{
            if(res.code==0){
                // this.handleResGantt(res.data,'update');
                this.curStep+=1;
                this.tempSerialNum=res.data.scheduledInfo.serialNumber;
                this.handleGanttdata('update');
                this.taskDialog=false;
            }else{
                this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
            }
        })
      },

      //甘特图拖拽验证
      handleDrop(dropData){
        console.log('拖拽验证',dropData);
        dragProcedure(dropData).then(res=>{
          if(res.code==0){
              console.log('拖拽验证成功',res.data);
              this.tempSerialNum=res.data.scheduledInfo.serialNumber;
              this.curStep+=1;
          }else{
              this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                })
          }
          this.handleGanttdata('update');
        })
      },


      //任务提示框详情
      tooltipFun(point){
        return `<div style='height:410px;line-height:34px;padding:0px 10px;'>
                  <div style='font-size: 24px;font-weight: bold;margin:4px 0px';>
                    ${point.partCode}
                  </div>
                  ${point.procedureName == "工步名称"? 
                  "": "工步名称：" + point.procedureName}<br/>
                  工单号： ${point.orderNo}<br/>
                  ${point.procedureName == "工步名称"? 
                  "投产号："+ point.productionNo: "排产号："+ point.schedulingNo}<br/>
                  
                  计划生产数量： ${point.plannedQuantity}<br/>
                  已完成数量： ${point.completedQuantity == null? "0": point.completedQuantity}<br/>
                  计划开始时间：<span> 
                    ${dayjs(point.start).format("YYYY-MM-DD HH:mm:ss")}</span><br/>
                  计划完成时间：${dayjs(point.end).format("YYYY-MM-DD HH:mm:ss")}<br/>
                  完成周期：${point.totalTimeStr}<br/>
                  工单需求日期：${point.orderCompletionDate}<br/>
                  交付情况：<span class="${point.overdueStatus=='normal'?'deliverGreen':'deliverRed'}">${point.varianceDay}</span>
                </div>`;
      },
    }

}
</script>


<style lang="scss">
.cfOrdinate1{
    width:7.3vw;font-size:16px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;
}
.cfOrdinate2{
    width:4vw;font-size:16px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;
}
</style>
<style lang="scss" scoped>
// .craft-wrapper{

// }
.cfganttFoot{
  height: 64px;
  width: 100%;
  background: #232323;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #B9B9B9;
  position: fixed;
  bottom: 0px;
  box-shadow: 0px 10px 30px rgb(0, 0, 0);
  >div{
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      margin-right: 10px;
      margin-left: 50px;
    }
  }
  >div:first-child{
    span{
      background: rgba(27,206,98,0.55);
      border: 1px solid #2DEB79;
    }
  }
  >div:last-child{
    span{
      background: rgba(255,77,79,0.55);
      border: 1px solid #FF4D4F;
      margin-left: 80px;
    }
  }
 
}

</style>