<template>
  <div class="calendar-wrapper" @click="curDate='';curTask=''">
    <div class="caleLeft">
      <div class="serialNum">{{scheduledInfo.serialNumber}}</div>
      <div :class="`status ${typeClass}`" v-if="scheduledInfo?.serialNumber"><span></span> {{ ganttType[scheduledInfo.scheduledStatus] }}</div>

      <div class="thumbnail">
        <div class="title">日历</div>
        <div class="dataBtn">
          <i @click="changeMonth('prev')" class="el-icon-arrow-left"></i>
          <i @click="changeMonth('next')" class="el-icon-arrow-right"></i>
        </div>
        <el-calendar v-model="value"></el-calendar>
      </div>

    </div>
    <div class="caleRight">
      <div class="title">
        <i class="el-icon-arrow-left"></i>
        {{titleDate}}
        <i class="el-icon-arrow-right"></i>
      </div>
      <el-calendar  v-model="value">
        <template slot="dateCell" slot-scope="{date,data}">
          <div class="dayDiv"> 
            <div class="today" v-if="new Date().toDateString()==date.toDateString()">今</div>
            {{data.day.split('-')[2]}} 
            <span class="lunar">{{ solarToLunar(date, data) }}</span>

            <div class="dayContent" v-if="craftData[data.day]">
              <div class="cont">
                <p><span></span><span>计划任务数：</span>{{craftData[data.day]?.totalTaskNumber}}</p>
                <p><span></span><span>设备负荷率：</span>{{Math.round(craftData[data.day]?.equipmentLoadRate*100)}}%</p>
              </div>
              <div @click.stop>
                <el-popover placement="right">
                  <div class="calenDetaile">
                    <p><span>计划任务数：</span>{{craftData[data.day]?.totalTaskNumber}}</p>
                    <p><span>设备负荷率：</span>{{Math.round(craftData[data.day]?.equipmentLoadRate*100)}}%</p>
                    <p class="his"><i class="el-icon-time"/>
                      {{craftData[data.day]?.latestReleaseScheduleTimeThisDay}} 发布 {{scheduledInfo.serialNumber}} 排程计划
                    </p>
                    <p><i class="el-icon-time"/>
                      {{craftData[data.day]?.latestProcessUpdateTimeThisDay}} 进行{{isSupportMes?'MES':'在制品'}}数据同步
                    </p>
                  </div>
                  <div slot="reference" class="other">
                    还有2项...
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </template>
      </el-calendar>

    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import dayjs from "dayjs";
import calendar from '@/utils/calendar.js';
import {getCalendarGantt} from '@/api/assemblyGantt.js';
export default {
  data() {
    return {
      value: new Date(),
      craftData:{
        '2023-03-24':{sj:1360,jh:2000,fh:100,cq:100,pc:"2023-03-10 10:20",tb:"2023-03-19 9:00"},
        '2023-03-22':{sj:50,jh:100,fh:20,cq:80,pc:"2023-03-10 10:20",tb:"2023-03-19 9:00"},
      },
      curDate:'',
      curTask:'',
      visible: false,
      // serialNumber:'',

      scheduledInfo:{},

    }
  },
  created(){
    this.handleCalendarGantt();
  },
  computed:{
    ...mapState(['isSupportMes','curSerialNum']),
    titleDate(){
      return dayjs(this.value).format('YYYY-MM');
    },
    ganttType(){
      return {
        0:'待保存',
        1:'未发布',
        2:'使用中',
        3:'已发布',
      }          
    },
    typeClass(){
      switch (this.scheduledInfo.scheduledStatus){
          case 1: return 'yellowColor'
          case 2: return 'greenColor'
          case 3: return 'blueColor'
          default: return 'whiteColor'
      }
    },
  },
  methods:{
    //获取日历甘特图
    handleCalendarGantt(){
      getCalendarGantt({serialNumber:this.curSerialNum}).then(res=>{
        console.log(res);
        if(res.code==0){
          this.craftData = res.data.dayTaskStatisticsMap;
          // this.serialNumber=res.data.scheduledInfo.serialNumber;          
          this.scheduledInfo=res.data.scheduledInfo          
        }else{
            this.$message.warning({
                    showClose: true,
                    message:res.msg,
                    duration:0
                });
        }
      })
    },

    //切换月份
    changeMonth(type){
      if(type=='prev'){
        this.value=new Date(this.value.setMonth(this.value.getMonth() - 1))
      }else{
        this.value=new Date(this.value.setMonth(this.value.getMonth() + 1))
      }
      
    },

    // 公历转农历
    solarToLunar(slotDate, slotData) {
      let solarDayArr = slotData.day.split('-');
      let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])
 
      // 农历日期
      let lunarMD = lunarDay.IMonthCn + lunarDay.IDayCn
 
      // 公历节日\农历节日\农历节气
      let festAndTerm = [];
      festAndTerm.push(lunarDay.festival == null ? '' : ' ' + lunarDay.festival)
      festAndTerm.push(lunarDay.lunarFestival == null ? '' : '' + lunarDay.lunarFestival)
      festAndTerm.push(lunarDay.Term == null ? '' : '' + lunarDay.Term)
      festAndTerm = festAndTerm.join('')
 
      return festAndTerm == '' ? lunarMD : festAndTerm
    }

   
  }
}
</script>
<style  lang="scss">
.calenDetaile {
    padding: 10px;
    div {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        margin-bottom: 20px;
    }
    span {
        display: inline-block;
        width: 150px;
    }
    p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        margin: 16px 0px
    }
    i {
        margin-right: 10px;
    }
    .his {
        margin-top: 30px
    }
}
// .el-popover{
//     background: #444;
//     border-radius: 12px;
// }
</style>
<style lang="scss" scoped>
@import '../styles/calendargantt.scss';
</style>